import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { baseURL } from '../../config';
import { openNotification } from '../../components/common/helper';
import { useNavigate } from 'react-router-dom';

const initialState = {
    token: typeof window !== 'undefined' && localStorage.getItem('token'),
    isLoading: false,
    error: null,
    preauths: [],
    property: {},
    preAuthDetails: { isLoading: false, preAuth: {} },
    snapshot: {}
};


export const listPreAuths = createAsyncThunk('preauths/List', async ({ size, page, status, sort }) => {
    return await fetch(`${baseURL}provider/pre-authorisation/list?size=${size}&page=${page}${(status && (status !== 'All')) ? ('&status=' + status) : ''}&sort=createdTimestamp,${sort}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(res => res.json())
        .catch(err => console.log(err))
});


// export const searchPreAuths = createAsyncThunk('preAuths/Search', async ({ query, size, page }) => {
//     return await fetch(`${baseURL}pas/claim/search?query=${query}&size=${size}&pageNumber=${page}`, {
//         method: 'GET',
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": "Bearer " + localStorage.getItem('token')
//         }
//     })
//         .then(res => res.json())
//         .catch(err => console.log(err))
// });


export const getPreAuthDetails = createAsyncThunk('preAuths/getPreAuths', async ({ navigate, preauth_id }) => {
    return await fetch(`${baseURL}provider/pre-authorisation/${preauth_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('token')
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res?.api?.responseCode !== 2040) {
                navigate('/pre-auths')
                openNotification(res?.message, 'Error')
            } else {
                return res;
            }

        })
        .catch(err => console.log(err))
});



export const PreAuthSlice = createSlice({
    name: 'preauths',
    initialState: initialState,
    reducers: {
        logOut: (state, actions) => {
            state.isLoading = false;
            state.isAuthenticated = false;
            state.token = null;
            localStorage.clear();
        },
        clearErr: (state, actions) => {
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder.addCase(listPreAuths.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(listPreAuths.fulfilled, (state, action) => {
            state.isLoading = false;
            state.preauths = action?.payload?.result?.content || [];
            state.preauths.property = action?.payload?.result?.property || null;
        });
        builder.addCase(listPreAuths.rejected, (state) => {
            state.isLoading = false;
        });
        // builder.addCase(searchPreAuths.pending, (state) => {
        //     state.isLoading = true;
        // });
        // builder.addCase(searchPreAuths.fulfilled, (state, action) => {
        //     state.preauths = action.payload.result.content
        //     state.property = action.payload.result.property
        //     state.isLoading = false;
        // });
        // builder.addCase(searchPreAuths.rejected, (state) => {
        //     state.isLoading = false;
        // });
        builder.addCase(getPreAuthDetails.pending, (state) => {
            state.preAuthDetails.isLoading = true;
        });
        builder.addCase(getPreAuthDetails.fulfilled, (state, action) => {
            state.preAuthDetails.preauth = action.payload.result
            state.preAuthDetails.isLoading = false;

        });
        builder.addCase(getPreAuthDetails.rejected, (state) => {
            state.preAuthDetails.isLoading = false;
        });

    },
});
export const { clearErr } = PreAuthSlice.actions
export default PreAuthSlice.reducer;
