import { initializeApp } from '@firebase/app'
import { getMessaging, getToken, onMessage } from '@firebase/messaging';


const firebaseConfig = {
  apiKey: 'AIzaSyAbRnazbniX9o2EFXIHJch87Q517L39Q5k',
  authDomain: 'eden-member-app.firebaseapp.com',
  projectId: 'eden-member-app',
  storageBucket: 'eden-member-app.appspot.com',
  messagingSenderId: '279948346289',
  appId: '1:279948346289:web:75f9a80c2610bc83e47390',
  measurementId: 'G-1H2ZLMPMGV',
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

// export const fetchToken = async (setTokenFound) => {
//   return await getToken(messaging, {
//     vapidKey:
//       'BHNlEpLODaG3lFaTycHqo17qFPJvB1QB9mEhMKt13DB6uEO_H7NwNjUbS_HoV-3MHLZBrB6J_n3oUyW27_pbsn0',
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         setTokenFound(currentToken);
//       } else {
//         setTokenFound(false);
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
