/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import useSound from 'use-sound';
import styles from './index.module.css';
import logoCollapsed from '../../img/sidebar/logoCollapsed.png';
import logoExpanded from '../../img/sidebar/logoExpanded.png';
import claims from '../../img/sidebar/claim.png';
import dashboard from '../../img/sidebar/dashboard.png';
import employers from '../../img/sidebar/employers.png';
import demoUser from '../../img/sidebar/avatar.png';
import support from '../../img/sidebar/support.png';
import policies from '../../img/sidebar/policies.png';
import fingerprint from '../../img/sidebar/fingerprint.png';
import wallet from '../../img/sidebar/wallet.svg';
import merchant from '../../img/sidebar/merchant.svg'
import provider from '../../img/sidebar/provider.png';
import reportsIcon from '../../img/sidebar/reports.png';
import insightsIcon from '../../img/sidebar/insights.png';
import notificationsIcon from '../../img/sidebar/bell.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoutOutlined, UsergroupAddOutlined, MenuOutlined, CloseOutlined, WalletOutlined, LayoutOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, notification } from 'antd';
import { baseURL } from '../../config';

import notiSound from './notification.mp3'

// import { fetchToken, onMessageListener } from '../../firebase';

import { getToken, onMessage } from "@firebase/messaging";
import { messaging } from "../../firebase";

const Context = React.createContext({
  name: 'Default',
});


const SideBar = () => {
  const [open, setOpen] = useState(false);

  const Onboarding = [
    {
      key: '',
      title: 'Dashboard',
      link: '/',
      icon: dashboard,
      visible: true,
      width: '25px',
      marginLeft: '37px',
    },

    {
      key: 'employers',
      title: 'Employers',
      link: '/employers',
      icon: employers,
      visible: true,
      // visible: JSON.parse(localStorage.getItem('user')).team_role?.filter(tr => tr.team === 'Employers').length > 0,
      width: '30px',
      marginLeft: '35px',
      invert: false,
    },
  ]

  const ClaimProcessing = [
    {
      key: '',
      title: 'Dashboard',
      link: '/',
      icon: dashboard,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0,
      width: '25px',
      marginLeft: '37px',
    },
    {
      key: 'claims',
      title: 'Claims',
      link: '/claims',
      icon: claims,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0,
      width: '25px',
      marginLeft: '37px',
    },
    {
      key: 'pre-auths',
      title: 'PreAuths',
      link: '/pre-auths',
      icon: fingerprint,
      width: '25px',
      marginLeft: '37px',
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0,
    },
  ]

  const PreAuthProcessing = [
    {
      key: '',
      title: 'Dashboard',
      link: '/',
      icon: dashboard,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Pre_Authorization').length > 0 && JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length === 0,
      width: '25px',
      marginLeft: '37px',
    },
    {
      key: 'pre-auths',
      title: 'PreAuths',
      link: '/pre-auths',
      icon: fingerprint,
      width: '25px',
      marginLeft: '37px',
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Pre_Authorization').length > 0 && JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length === 0,
    },
  ]

  const CustomerSupport = [
    {
      key: 'customer-support',
      title: 'Customer Support',
      link: '/customer-support',
      icon: support,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Support').length > 0,
      width: '30px',
      marginLeft: '34px',
      invert: true,
    },

  ]

  const Notifications = [
    {
      key: 'notifications',
      title: 'Notifications',
      link: '/notifications',
      icon: notificationsIcon,
      visible: true,
      width: '30px',
      marginLeft: '34px',
      invert: true,
    },

  ]

  const Reports = [
    {
      key: 'reports',
      title: 'Reports',
      link: '/reports',
      icon: reportsIcon,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Support').length > 0 || JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0,
      width: '32px',
      marginLeft: '34px',
      invert: true,
    },
  ]

  const Insights = [
    {
      key: 'insights',
      title: 'Insights',
      link: '/insights',
      icon: insightsIcon,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Support').length > 0 || JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0,
      width: '34px',
      marginLeft: '34px',
      invert: true,
    },

  ]

  const Sales = [
    {
      key: '',
      title: 'Dashboard',
      link: '/',
      icon: dashboard,
      visible: true,
      width: '25px',
      marginLeft: '37px',
    },
  ]

  const Finance = [
    {
      key: 'providers',
      title: 'Provider Payments',
      link: '/providers',
      icon: provider,
      width: '30px',
      marginLeft: '34px',
      invert: true,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Finance').length > 0,
    },
    {
      key: 'merchant-payments',
      title: 'Merchant Payments',
      link: '/merchant-payments',
      icon: merchant,
      width: '30px',
      marginLeft: '34px',
      invert: true,
      visible: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Finance').length > 0,
    },
  ]

  const Underwriting = [
    {
      key: 'employer-activities',
      title: 'Employer Activities',
      link: '/employer-activities',
      icon: employers,
      visible: ['sumit@edencaremedical.com', 'snjeru@edencaremedical.com', 'penelope@edencaremedical.com', 'brice@edencaremedical.com', 'bruce.nelly@edencaremedical.com', 'ekansh@edencaremedical.com'].includes(JSON.parse(localStorage.getItem('user')).email),
      width: '30px',
      marginLeft: '34px',
      invert: false,
    },
  ]

  const loc = useLocation()

  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 4 }, maxCount: 6 });

  const openNotification = (content) => {
    api.open({
      key: content.key || null,
      message: content.title,
      description: content.description,
      duration: 0,
      className: 'global-notification'
    });
  };

  const [notificationCount, setNotificationCount] = useState(0)

  const audio = new Audio(notiSound)

  const sendTokenToServer = async (deviceToken) => {
    await fetch(`${baseURL}user/device-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ device_token: deviceToken })
    }).then(r => r.json())
      .catch(e => console.log(e))
  };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: 'BHNlEpLODaG3lFaTycHqo17qFPJvB1QB9mEhMKt13DB6uEO_H7NwNjUbS_HoV-3MHLZBrB6J_n3oUyW27_pbsn0',
      });
      sendTokenToServer(token)
    } else if (permission === "denied") {
      openNotification({ title: 'Alert', description: 'Please grant notifications permissions in browser.' })
    }
  }

  onMessage(messaging, (payload) => {
    if (localStorage.getItem('notificationsMuted') !== 'true') {
      audio.play()
    }
    fetchNotifications()
    openNotification({ title: payload.notification.title, description: payload.notification.body })
  });

  const fetchNotifications = async () => {
    await fetch(`${baseURL}notification/count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setNotificationCount(data.result.count))
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    if (!notificationCount) {
      fetchNotifications()
    }
  }, []);



  useEffect(() => {
    requestPermission();

    notifyBroadcast.onmessage = (event) => {
      if (localStorage.getItem('notificationsMuted') !== 'true') {
        audio.play()
      }
      fetchNotifications()
      openNotification({ title: event.data.notification.title, description: event.data.notification.body })
    }
    setInterval(() => {
      fetchNotifications()
      if (notificationCount > 0) {
        if (localStorage.getItem('notificationsMuted') !== 'true') {
          audio.play()
        }
        openNotification({ key: 'Reminder', title: 'Alert', description: `You have ${notificationCount} pending Pre-Auth Requests.` })
      }
    }, 300000)
  }, [notificationCount]);

  const notifyBroadcast = new BroadcastChannel('notifyChannel');






  useEffect(() => setCurrent(loc.pathname.split('/')[1]), [loc])

  const [current, setCurrent] = useState(loc.pathname)
  const navigate = useNavigate()

  const [userHover, setUserHover] = useState(false)

  const logOut = () => {
    localStorage.clear()
    navigate('/login')
  }

  const [isViewModalOpen, setIsViewModalOpen] = useState(false)

  const renderLinks = links => links.map((link, i) => link.visible && <div
    key={i}
    className={styles.linkContainer}
    onClick={() => navigate(link.link)}
    style={{
      backgroundColor: current === link.key ? 'rgba(58, 180, 67, 0.65)' : null,
      boxShadow: current === i ? '2px 2px 5px 2px rgba(82, 82, 82, 0.15)' : null,
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      alignItems: 'center',

    }}
  >
    <img src={link.icon} style={{
      marginLeft: '35px',
      width: link.width,
      marginLeft: link.marginLeft,
      // filter: link.invert
      filter: (link.invert) ? (current === link.key ? 'invert(1)' : 'invert(0.45)') : (current === link.key ? 'invert(0)' : 'invert(0.45)'),
    }}
    />{open ? <span style={{ color: current === link.key ? 'white' : '' }}>{link.title}</span> : null}
  </div>)

  return (
    <div
      className={open ? styles.sideBarContainerOpen : styles.sideBarContainer}
    >
      {contextHolder}
      <Modal width={'550px'} open={isViewModalOpen} footer={null} bodyStyle={{ padding: '0px' }} closable={false} >
        <div className={styles.modalContainer} style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            className={styles.closeBtn}
            onClick={() => setIsViewModalOpen(false)}
          >
            <CloseOutlined />
          </Button>
          <div style={{ display: 'flex', gap: '25px' }}>
            <div className={styles.widgetShadow} onClick={() => { localStorage.setItem('dashboard', 'Claims'); navigate('/'); window.location.reload(); }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '30px' }}>
              <h4 className={styles.modalSubTitle} style={{ fontSize: '18px', fontWeight: '500' }}>
                Claims
              </h4>
            </div>
            <div className={styles.widgetShadow} onClick={() => { localStorage.setItem('dashboard', 'Finance'); navigate('/'); window.location.reload(); }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '150px', height: '30px' }}>
              <h4 className={styles.modalSubTitle} style={{ fontSize: '18px', fontWeight: '500' }}>
                Finance
              </h4>
            </div>
          </div>

        </div>
      </Modal >
      <img src={open ? logoExpanded : logoCollapsed} style={{ width: open ? '100px' : '40px', opacity: '75%' }} />
      <div style={{ position: 'relative', width: '100%', height: '80vh', justifyContent: 'flex-start', alignItems: 'center', marginTop: open ? '50px' : '90px', display: 'flex', flexDirection: 'column', gap: '0px', padding: '0px 20px' }}>
        <div
          style={{
            display: 'flex',
            gap: '15px',
            alignItems: 'center',
            justifyContent: open ? 'flex-end' : 'center',
            width: '100%',
            height: '50px',
            transition: 'all 0.15s ease-in-out',
            borderRadius: '0px 15px 15px 0px',
            cursor: 'pointer',
            marginRight: '2px'
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? <CloseOutlined style={{ fontSize: '22px', marginRight: '15px' }} /> : <MenuOutlined style={{ fontSize: '22px' }} />}

        </div>

        {renderLinks(ClaimProcessing)}
        {renderLinks(PreAuthProcessing)}
        {renderLinks(CustomerSupport)}
        {renderLinks(Finance)}
        {renderLinks(Underwriting)}

        {/* <div style={{ position: 'absolute', bottom: '70px', width: '100px' }}> */}
        {/* <div
          // className={styles.linkContainer}
          onClick={() => setPlaying(true)}
          style={{
            position: 'absolute',
            top: '100px',
            backgroundColor: current === 'notifications' ? 'rgba(58, 180, 67, 0.65)' : null,
            boxShadow: current === 'notifications' ? '2px 2px 5px 2px rgba(82, 82, 82, 0.15)' : null,
            display: 'flex',
            justifyContent: 'flex-start',
            width: open ? '250px' : '100px',
            alignItems: 'center',
          }}
        >
          <Badge count={notificationCount}>
            <img src={notificationsIcon} style={{
              marginLeft: '35px',
              width: '30px',
              marginLeft: '34px',
              filter: (current === 'notifications' ? 'invert(1)' : 'invert(0.45)'),
            }}
            />
          </Badge>
        </div> */}
        <div
          className={styles.linkContainer}
          onClick={() => navigate('/notifications')}
          style={{
            position: 'absolute',
            top: '-50px',
            backgroundColor: current === 'notifications' ? 'rgba(58, 180, 67, 0.65)' : null,
            boxShadow: current === 'notifications' ? '2px 2px 5px 2px rgba(82, 82, 82, 0.15)' : null,
            display: JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Pre_Authorization').length > 0 || JSON.parse(localStorage.getItem('user'))?.team_role?.filter(tr => tr.team === 'Claim').length > 0 ? 'flex' : 'none',
            justifyContent: 'flex-start',
            width: open ? '250px' : '100px',
            alignItems: 'center',

          }}
        >
          <Badge count={notificationCount}>
            <img src={notificationsIcon} style={{
              marginLeft: '35px',
              width: '30px',
              marginLeft: '34px',
              filter: (current === 'notifications' ? 'invert(1)' : 'invert(0.45)'),
            }}
            />
          </Badge>
          {open ? <span style={{ color: current === 'notifications' ? 'white' : '' }}>Notifications</span> : null}
        </div>
        {/* </div> */}


        <div
          onMouseEnter={() => setUserHover(true)}
          onMouseLeave={() => setUserHover(false)}
          className={styles.userContainer}>
          {/* {userHover ? <div style={{ marginBottom: open ? '82px' : '55px', marginLeft: open ? '-10px' : '15px' }} onClick={() => setIsViewModalOpen(true)} className={open ? styles.userMgmtBtnOpen : styles.userMgmtBtn}><LayoutOutlined style={{ marginLeft: '2px' }} /><span className={styles.btnHoverText}>Change View</span></div> : null} */}
          {userHover && JSON.parse(localStorage.getItem('user')).team_role?.filter(tr => tr.role === 'Super_Admin').length > 0 ? <div style={{ marginBottom: open ? '25px' : '', marginLeft: open ? '-10px' : '15px' }} onClick={() => navigate('/users')} className={open ? styles.userMgmtBtnOpen : styles.userMgmtBtn}><UsergroupAddOutlined style={{ marginLeft: '2px' }} /><span className={styles.btnHoverText}>Manage Users</span></div> : null}
          {userHover ?
            <div className={open ? styles.logoutBtnOpen : styles.logoutBtn} style={{ marginBottom: open ? '25px' : '', marginLeft: open ? '-10px' : '15px' }} onClick={logOut}>
              <LogoutOutlined style={{ marginLeft: '2px' }} />
              <span className={styles.btnHoverText}>Logout</span>
            </div> : null}
          <img src={demoUser} className={open ? styles.userIconOpen : styles.userIcon} />
          {open ? <span style={{ position: 'absolute', right: '45px', bottom: '25px' }}>{JSON.parse(localStorage.getItem('user'))?.name}</span> : null}
        </div>
      </div>
    </div >
  );
};

export default SideBar;
