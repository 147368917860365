import SideBar from './SideBar.js';
import styles from './index.module.css';

function Main({ children, pageName }) {
    return (
        <div className={styles.pageContainer}>
            <SideBar />
            <div className={styles.contentContainer}>
                <div >{children}</div>
            </div>
        </div>
    );
}

export default Main;
