import { notification } from 'antd';
import { baseURL } from '../../config';
import { saveAs } from 'file-saver';

export const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

export const toCamelCase = (str) => {
    return str.replaceAll(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replaceAll(/\s+/g, '');
}

export const checkUppercaseAndNumb = (str) => {
    if (/[^a-zA-Z]/.test(str) && str !== str.toLowerCase()) {
        return true;
    } else {
        return false;
    }
};


export const openNotification = (msg, title) => {
    notification.open({
        message: title,
        description: msg,
        style: {
            borderRadius: '15px',
        },
    });
};


export const role = JSON.parse(localStorage.getItem('user'))?.role




export const downloadProviderSnapshot = async ({ setSnapshotLoading }) => {
    setSnapshotLoading(true)
    await fetch(`${baseURL}pas/onboard/report/provider/snapshot/excel`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Content-Type': 'application/octect-stream' }
    })
        .then(res => {
            const filename = res.headers.get('Content-Disposition').split('filename=')[1];
            res.blob().then((blob) => saveAs(blob, filename));
        })
        .finally(() => setSnapshotLoading(false))
}

export const Required = <span style={{ fontSize: '12px', fontWeight: '300', fontStyle: 'italic' }}>Required</span>

export const statusColor = (status) => {
    switch (status) {
        case ('Active'):
            return ('#5cb85c');
        case ('Inactive'):
            return ('#f0ad4e');
        case ('Suspended'):
            return ('#d9534f');
        case ('Deleted'):
            return ('#d9534f');
        case ('Pending'):
            return ('#5bc0de');
        case ('Approved'):
            return ('#5cb85c');
        case ('Declined'):
            return ('#d9534f');
        case ('In_Progress'):
            return ('#0275d8');
        case ('Information_Required'):
            return ('#f0ad4e');
        case ('Information_Submitted'):
            return ('#4e9af0');
    }
}
