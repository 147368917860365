import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { baseURL } from '../../config';

const initialState = {
    token: typeof window !== 'undefined' && localStorage.getItem('token'),
    isLoading: false,
    error: null,
    employers: [],
    property: {},
    details: { isLoading: false, claim: null },
    snapshot: {},
};



export const employerSlice = createSlice({
    name: 'claims',
    initialState: initialState,
    reducers: {

        clearErr: (state, actions) => {
            state.error = null;
        },
    },
    extraReducers(builder) {

    },
});
export const { clearErr } = employerSlice.actions
export default employerSlice.reducer;
