import RouteSetup from './router/RouteSetup';

function App() {



  return (
    <RouteSetup />
  );
}

export default App;
