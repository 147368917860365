import { configureStore } from '@reduxjs/toolkit';

import auth from './authSlice';
import claims from './ClaimProcessing/claimSlice'
import preauths from './ClaimProcessing/preauthSlice'
import employerSlice from './Onboarding/employerSlice'
export const store = configureStore({
    reducer: {
        auth,
        claims,
        preauths
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
